<template>
  <div>
    <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        :size="formSize"
        :label-position="'top'"
    >
      <!--      {{ form }}-->
      <div id="created_customer"></div>
      <Cards :title="$t('common.record.create')" :isShowTitle="true">
        <slot name="createMessage"></slot>
      </Cards>
      <Cards :title="$t('common.cds25')" :isShowTitle="true">
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item :label="$t('common.establishment')" prop="setUpTime">
              <el-date-picker
                  v-model="ruleForm.setUpTime"
                  type="date"
                  :placeholder="$t('sys.tip.select.date')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('common.economicnature')" prop="economicNature">
              <!--              <el-select v-model="ruleForm.economicNature" :placeholder="$t('common.please.select')">-->
              <!--                <el-option :label="$t('common.private.business')" :value="$t('common.private.business')"/>-->
              <!--                <el-option :label="$t('common.stateowned.business')" :value="$t('common.stateowned.business')"/>-->
              <!--              </el-select>-->
              <el-input
                  v-model="ruleForm.economicNature"
                  :placeholder="$t('common.please.input')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('common.company.website')">
              <el-input
                  v-model="ruleForm.companyWebsite"
                  :placeholder="$t('common.please.input')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('common.cds2')">
              <el-input
                  v-model="ruleForm.companyAddress"
                  :placeholder="$t('common.please.input')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="margin-top: 20px">
          <el-col :span="6">
            <el-form-item prop="phone">
              <MoreCheck :label="$t('common.contact.phone')" :title="$t('common.contact.phone.add')">
                <el-input
                    v-model="defaultPhoneNumber"
                    :placeholder="$t('common.please.input')"
                />
              </MoreCheck>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('common.faxnumber')" prop="companyFax">
              <el-input v-model="ruleForm.companyFax" :placeholder="$t('common.please.input')"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--                        {{form.emails}}-->
            <el-form-item prop="email">
              <MoreCheck :label="$t('common.cds6')" :title="$t('common.emailbox.add')">
                <el-input v-model="defaultEmail"
                          :placeholder="$t('common.please.input')"/>
              </MoreCheck>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('common.other.remark')" prop="companyRemark">
              <el-input
                  v-model="ruleForm.companyRemark"
                  @click="showTextarea"
                  :placeholder="$t('common.please.input')"
              />
              <el-dialog v-model="otherDesCompanyVisible" :title="$t('common.remark')">
                <template #default>
                  <el-input
                      v-model="ruleForm.companyRemark"
                      type="textarea"
                      :rows="5"
                      :placeholder="$t('common.please.input')"
                  />
                </template>
                <template #footer style="border-top: 1px solid">
                  <div class="dialog-footer">
                    <el-button @click="otherDesCompanyVisible = false" style="margin-right: 20px"
                    >{{ $t('common.sss16') }}
                    </el-button
                    >
                    <el-button
                        type="primary"
                        @click="otherDesCompanyVisible = false"
                    >{{ $t('common.save') }}
                    </el-button
                    >
                  </div>
                </template>
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
      </Cards>
      <Cards :title="$t('common.marketing.information')" :isShowTitle="true">
        <el-row :gutter="24" style="margin-top: 20px">
          <el-col :span="6">
            <el-form-item :label="$t('common_customer_level')" prop="customerLevel">
              <el-select
                  v-model="ruleForm.customerLevel"
                  :placeholder="$t('common.please.select')"
                  @change="selectChange"
              >
                <el-option
                    v-for="item in selections"
                    :label="item.customerLevel"
                    :value="item.id"
                    :key="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('common.customer.type')" prop="customerType">
              <el-input disabled v-model="selectTarget" :placeholder="$t('common.please.input')"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('common.interest.product')" prop="product">
              <el-input v-model="ruleForm.product" :placeholder="$t('common.please.input')"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('create.customer.des')" prop="marketingRemark">
              <el-input
                  style="white-space: pre-wrap;word-break: break-all"
                  v-model="ruleForm.marketingRemark"
                  :placeholder="$t('common.please.input')"
                  @click="otherDesSalVisible = true"
              />
              <el-dialog v-model="otherDesSalVisible" :title="$t('create.customer.des')">
                <template #default>
                  <el-input
                      style="white-space: pre-wrap;word-break: break-all"
                      v-model="ruleForm.marketingRemark"
                      type="textarea"
                      :rows="5"
                      :placeholder="$t('common.please.input')"
                  />
                </template>
                <template #footer style="border-top: 1px solid">
                  <div class="dialog-footer">
                    <el-button @click="otherDesSalVisible = false" style="margin-right: 20px"
                    >{{ $t('common.sss16') }}
                    </el-button
                    >
                    <el-button
                        type="primary"
                        @click="otherDesSalVisible = false"
                    >{{ $t('common.save') }}
                    </el-button
                    >
                  </div>
                </template>
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
      </Cards>
      <Cards :title="$t('common.contact.add')" v-if="isCreate" :isShowTitle="true">
        <el-row :gutter="24">
          <el-col :span="6">
            <el-button
                style="margin-bottom: 20px"
                type="primary"
                @click="dialogVisible = true"
            >{{ $t("common.contact.add") }}
            </el-button>
          </el-col>
        </el-row>
        <el-table :key="mainKey" class="tableAuto" v-loading="isLoading" :data="targetTableData" style="width: 100%"
                  :header-cell-style="{'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}">
          <el-table-column
              v-for="(item, index) in targetMaps"
              :key="item.key"
              :label="item.key"
              :prop="item.value"
              label="Date"
              :width="item.value==='email'||item.value==='name'?300:200"
          >
            <template v-if="item.value==='remark'" #default="scope">
              <el-icon
                  :color="scope.row.remark ? '#40a9ff' : '#dddddd'">
                <el-tooltip
                    v-if="scope.row.remark"
                    class="box-item"
                    :content="scope.row.remark"
                    placement="right"
                    popper-class="tips_width"
                >
                  <Memo/>
                </el-tooltip>
                <Memo v-else/>
              </el-icon>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operate')" fixed="right" width="120">
            <template #default="{ row, column, $index }">
              <el-button
                  type="primary"
                  link
                  @click="clickEdit(row, column, $index)"
              >{{ $t('common.edit') }}
              </el-button
              >
              <ConfirmDeletePopover
                  :visible="row.popoverVisible"
                  :width="168"
                  :message="$t('sys.message.ask.contect.delete')"
                  @update:visible="(value) => (row.popoverVisible = value)"
                  @confirm:delete="handleDelete(row, column, $index)"
              >
                <template #settings>
                  <el-button type="danger" link @click="handleDeleteT(row, column, $index)">{{ $t('common.delete') }}
                  </el-button>
                </template>
              </ConfirmDeletePopover>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
          </template>
        </el-table>
        <el-dialog
            v-model="dialogVisible"
            width="1000"
            style="min-width: 600px"
            :append-to-body="true"
            @close="cancelClick"
            top="3vh"
        >
          <!--          :title="-->
          <!--          !isEdit-->
          <!--          ? ''-->
          <!--          : $t('common.email.updateContacts')-->
          <!--          "-->
          <div class="tops">
            <div class="span_block"></div>
            <div class="inner_text">联系人信息</div>
          </div>
          <el-form
              ref="ruleFormDom"
              :model="ruleFormUsers"
              :rules="rules"
              label-width="119px"
              class="demo-ruleForm"
          >
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                    :label="$t('common.name')"
                    prop="name"
                >
                  <el-input
                      v-model="ruleFormUsers.name"
                      :placeholder="$t('90201')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('common.sex')" prop="sex">
                  <el-radio-group
                      v-model="ruleFormUsers.sex"
                      class="ml-4"
                  >
                    <el-radio :label="1" :value="1">{{
                        $t("common.man")
                      }}
                    </el-radio>
                    <el-radio :label="2" :value="2">{{
                        $t("common.woman")
                      }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                    :label="$t('common.company.position')"
                    prop="post"
                >
                  <el-input
                      v-model="ruleFormUsers.post"
                      :placeholder="$t('sys.tip.input.position')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    :label="$t('common.phone.number')"
                    prop="phone"
                >
                  <el-input
                      v-model="ruleFormUsers.phone"
                      :placeholder="$t('sys.tip.input.phone')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                    :label="$t('common.contact.phone')"
                    prop="telPhone"
                >
                  <el-input
                      v-model="ruleFormUsers.telPhone"
                      :placeholder="$t('sys.tip.input.contact.phone')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    :label="$t('common.dateofbirth')"
                    prop="birthday"
                >
                  <el-date-picker
                      v-model="ruleFormUsers.birthday"
                      type="date"
                      value-format="YYYY-MM-DD"
                      :placeholder="$t('sys.tip.select.date')"
                      :aria-placeholder="$t('sys.tip.select.date')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item
                    :label="$t('common.remark')"
                    prop="remark"
                >
                  <el-input
                      v-model="ruleFormUsers.remark"
                      type="textarea"
                      :placeholder="$t('sys.tip.input.remark')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <MyTitles :title="$t('common.emailbox')">
                  <template #content>
                    <div class="bottoms">
                      <el-form-item
                          class="sp_form_item"
                          v-for="(
                                      item, index
                                    ) in ruleFormUsers.mailContactEmailList"
                          style="margin-top: 20px;padding-bottom: 0px"
                          :key="index"
                          :label="index === 0 ? $t('common.emailbox') : ''"
                          :prop="
                                      'mailContactEmailList[' +
                                      index +
                                      '].email'
                                    "
                          :rules="[
                                      {
                                        pattern:
                                          emailReg,
                                        message: $t('common.email.format.wrong'),
                                         trigger: ['change','blur'],
                                      },
                                    ]"
                      >
                        <div class="email-input">
                          <el-input
                              v-model="item.email"
                              :placeholder="$t('20202')"
                          />
                          <el-icon
                              v-if="
                                          ruleFormUsers.mailContactEmailList
                                            .length !== 1
                                        "
                              style="
                                          font-size: 20px;
                                          color: #d93737;
                                          margin-left: 20px;
                                          cursor: pointer;
                                        "
                              @click="removeEmail(index)"
                          >
                            <RemoveFilled/>
                          </el-icon>
                          <div v-else style="width: 44px"></div>
                        </div>
                      </el-form-item>
                      <el-form-item>
                        <el-button
                            @click="addEmail"
                            id="spButton"
                            :icon="Plus"
                        >
                          {{ $t("common.add") }}
                        </el-button>
                      </el-form-item>
                    </div>
                  </template>
                </MyTitles>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <MyTitles :title="$t('common.memorialday')">
                  <template #content>
                    <div class="remembers">
                      <!-- <RememberDate></RememberDate> -->
                      <div>
                        <div
                            v-for="(
                                        event, index
                                      ) in ruleFormUsers.mailContactSouvenirList"
                            :key="index"
                            class="event-input"
                        >
                          <template v-if="!event.editing">
                            <div class="out_box">
                              <div
                                  class="event-text"
                                  @click="editEvent(index)"
                              >
                                {{ event.remark }}
                              </div>
                              <div class="event-text"></div>
                            </div>
                          </template>
                          <el-input
                              v-if="event.editing"
                              v-model="event.remark"
                              :placeholder="$t('common.contact.info.anniversary.name')"
                              @focus="showButtons(index)"
                              @blur="hideButtons(index)"
                              ref="inputRef"
                          ></el-input>
                          <div style="margin: 0 10px"></div>
                          <el-date-picker
                              v-model="event.souvenirDay"
                              type="date"
                              class="event-text"
                              :placeholder="$t('common.please.select')"
                          />
                          <div class="setting">
                            <el-button
                                v-if="!event.showButtons"
                                @click="updateEvent(index)"
                                icon="el-icon-check"
                                type="primary"
                                link
                            >{{ $t('common.edit') }}
                            </el-button>
                            <el-button
                                v-if="event.showButtons"
                                @click="removeEvent(index)"
                                icon="el-icon-delete"
                                link
                                type="danger"
                            >{{ $t('common.delete') }}
                            </el-button>
                            <el-button
                                v-if="!event.showButtons"
                                @click="removeEvent(index)"
                                icon="el-icon-delete"
                                link
                                type="danger"
                            >{{ $t('common.delete') }}
                            </el-button>
                          </div>
                        </div>

                        <el-button @click="addEvent" type="primary"
                        >{{ $t('common.memorialday.add') }}
                        </el-button>
                      </div>
                    </div>
                  </template>
                </MyTitles>
              </el-col>
            </el-row>
          </el-form>
          <template #footer>
                        <span class="dialog-footer">
                          <el-button
                              style="margin: 0px 6px"
                              @click="cancelClick"
                          >{{ $t("common.cancel") }}</el-button
                          >
                          <el-button
                              style="margin: 0px 6px"
                              type="primary"
                              @click="addCustomerContact"
                          >
                            {{ $t("common.save") }}
                          </el-button>
                        </span>
          </template>
        </el-dialog>
      </Cards>
      <Cards>
        <el-row v-if="isShowButton" :gutter="24">
          <el-col :span="24">
            <el-form-item id="submits_footer">
              <el-button style="margin: 0px 8px !important" @click="reBack"
              >{{ $t('common.back') }}
              </el-button
              >
              <el-button
                  :loading="saveDataIsLoading"
                  style="margin: 0px 8px !important"
                  type="primary"
                  @click="saveData"
              >{{ $t('common.save') }}
              </el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </Cards>
    </el-form>
  </div>

</template>

<script setup>
import {
  computed,
  ref,
  onMounted,
  reactive,
  defineProps,
  defineExpose,
  defineEmits,
  inject,
  nextTick,
  watch,
  watchEffect
} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {v4 as uuidv4} from 'uuid';
import Cards from "../Cards.vue";
import MoreCheck from "./MoreCheck.vue";
import {throttle} from "@/utils/mylodash"
import {customerCodeReg, emailReg, emptyUrl} from "@/utils/statusParams";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import {Plus} from "@element-plus/icons-vue";
import MyTitles from "@/views/Customer/Detail/MyTitles.vue";
import {ElMessage} from "element-plus";
import dayjs from "dayjs";
import {i18n} from "@/lang";

const router = useRouter();
const route = useRoute();
const store = useStore();
const emit = defineEmits()
const {isCreate, isShowButton} = defineProps({
  isShowButton: {
    type: Boolean,
    default: true
  },
  isCreate: {
    type: Boolean,
    default: false
  }
})


// 编辑
const isEdit = ref(false);
// 添加联系人
const dialogVisible = ref(false);
const targetMaps = [
  {key: i18n.global.t('common.name'), value: "name"},
  {key: i18n.global.t('common.position'), value: "post"},
  {key: i18n.global.t('common.contact.phone'), value: "telPhone"},
  {key: i18n.global.t('common.phone.number'), value: "phone"},
  {key: i18n.global.t('common.emailbox'), value: "email"},
  {key: i18n.global.t('common.birthday'), value: "birthday"},
  // {key: i18n.global.t('common.record.time'), value: "createTime"},
  {key: i18n.global.t('common.remark'), value: "remark"},
];
const isLoading = ref(false);
// 表单数据
const ruleFormUsers = reactive({
  name: "",
  sex: 1,
  post: "",
  phone: "",
  telPhone: "",
  birthday: null,
  remark: "",
  mailContactEmailList: [
    {
      email: "",
      isDefault: 0,
    },
  ],
  mailContactSouvenirList: [],
});

const cancelClick = () => {
  dialogVisible.value = false;
  isEdit.value = false
  ruleFormUsers.name = "";
  ruleFormUsers.sex = 1;
  ruleFormUsers.post = "";
  ruleFormUsers.phone = "";
  ruleFormUsers.telPhone = "";
  ruleFormUsers.birthday = null;
  ruleFormUsers.remark = "";
  ruleFormUsers.mailContactEmailList = [
    {
      email: "",
      isDefault: 0,
    },
  ];
  ruleFormUsers.mailContactSouvenirList = [];
};

const editEvent = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].editing = false;
  nextTick(() => {
    inputRefs[index].focus();
  });
};

const updateEvent = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].editing = true;
  ruleFormUsers.mailContactSouvenirList[index].showButtons = true;
};

const removeEvent = (index) => {
  ruleFormUsers.mailContactSouvenirList.splice(index, 1);
};

const showButtons = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].showButtons = true;
};

const hideButtons = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].showButtons = false;
  ruleFormUsers.mailContactSouvenirList[index].editing = false;

  if (ruleFormUsers.mailContactSouvenirList[index].remark === "") {
    removeEvent(index);
  }
};


// 添加纪念日
const dates = ref([{value: new Date()}]);
const addEvent = () => {
  if (ruleFormUsers.mailContactSouvenirList.filter(item => item.souvenirDay === "").length >= 1) {
    ElMessage.warning("请填写完整后再进行添加")
    return;
  } else {
    ruleFormUsers.mailContactSouvenirList.push({
      remark: "",
      souvenirDay: "",
      editing: true,
      showButtons: true,
    });
    dates.value.push({value: new Date()});
  }
};


// 添加邮箱地址
const addEmail = () => {
  if (
      ruleFormUsers.mailContactEmailList.filter((item) => item.email == "")
          .length >= 1
  ) {
    ElMessage.error("请先填写邮箱后再进行添加");
  } else {
    ruleFormUsers.mailContactEmailList.push({
      email: "",
      isDefault: 0,
    });
    ruleFormUsers.mailContactEmailList[0].isDefault = 1;
  }
};

// 原数据
const tableData = reactive([])
// table 可以被识别的数据
let targetTableData = []
const mainKey = ref(1)
const ruleFormDom = ref(null);

// 添加联系人
const addCustomerContact = throttle(() => {
  let teamId=ruleForm.value.teamId;
  ruleFormDom.value.validate(async (valid, fields) => {
    if (valid) {
      const copObj = JSON.parse(JSON.stringify(ruleFormUsers));
      const {mailContactSouvenirList, mailContactEmailList} = copObj;
      let resMails = mailContactSouvenirList.map((item) => {
        return {
          ...item,
          souvenirDay: item.souvenirDay && dayjs(item.souvenirDay).isValid()
              ? dayjs(item.souvenirDay).format("YYYY-MM-DD")
              : null,
        };
      });

      //默认邮箱
      const selectDefault = mailContactEmailList.some(
          (item) => item.isDefault === 1
      )
          ? mailContactEmailList.filter(_ => _.email !== "")
          : mailContactEmailList.map((item, index) => {
            if (index === 0) {
              return {isDefault: 1, email: item.email};
            } else {
              return {...item, isDefault: 1};
            }
          }).filter(_ => _.email !== "");
      delete copObj.birthday;
      delete copObj.mailContactSouvenirList;
      delete copObj.mailContactEmailList;

      const targetObj = {
        ...copObj,
        birthday:
            (ruleFormUsers.birthday && dayjs(ruleFormUsers.birthday).isValid())
                ? dayjs(ruleFormUsers.birthday).format("YYYY-MM-DD")
                : null,
        mailContactSouvenirList: resMails,
        mailContactEmailList: selectDefault,
        teamId,
      };

      console.log(targetObj, 'targetObj')
      // 这里需要将targetObj 转换成 table 可以识别的数据
      if (!isEdit.value) {
        console.log({...targetObj, id: uuidv4()}, "添加");
        tableData.push({...targetObj, id: uuidv4()})
        localStorage.setItem("tableData", JSON.stringify(tableData))
        dialogVisible.value = false;
        isEdit.value = false;
      } else {
        console.log(targetObj, "编辑");
        //   修改
        Object.assign(tableData, targetTableData.value.map(item => {
          if (item.id === contactId.value) {
            return {id: contactId.value, ...targetObj}
          } else {
            return item
          }
        }))
        localStorage.setItem("tableData", JSON.stringify(tableData))
        dialogVisible.value = false;
        isEdit.value = false;
      }
      ruleFormDom.value.resetFields();
    } else {
      // console.log("error submit!", fields);
    }
  });
}, 1000);

// 记录联系人行id
const contactId = ref("");
// 编辑联系人
const clickEdit = async (row, column, $index) => {
  console.log(row, 'row')
  isEdit.value = true;
  const {id} = row;
  contactId.value = id;
  dialogVisible.value = true;
  const contactMessage = targetTableData.value.filter(item => item.id === id)[0]
  console.log(contactMessage, 'contactMessage')
  const {
    name,
    post,
    phone,
    telPhone,
    birthday,
    remark,
    sex,
    mailContactEmailList,
    mailContactSouvenirList,
  } = contactMessage;
  ruleFormUsers.name = name;
  ruleFormUsers.post = post;
  ruleFormUsers.phone = phone;
  ruleFormUsers.telPhone = telPhone;
  ruleFormUsers.birthday = birthday;
  ruleFormUsers.sex = sex;
  ruleFormUsers.remark = remark;
  ruleFormUsers.mailContactEmailList = mailContactEmailList.length === 0 ? [{
    email: "",
    isDefault: 0,
  }] : mailContactEmailList;
  ruleFormUsers.mailContactSouvenirList = mailContactSouvenirList;
};


const removeEmail = (index) => {
  ruleFormUsers.mailContactEmailList.splice(index, 1);
};


const handleDelete = async (row, column, $index) => {
  tableData.splice(tableData.findIndex(item => item.id === row.id), 1)
  localStorage.setItem("tableData", JSON.stringify(tableData))
  row.popoverVisible = false;
};

const handleDeleteT = async (row, column, $index) => {
  row.popoverVisible = true;
}


watch(() => tableData, (newValue, oldValue) => {
  console.log(newValue, oldValue, 'tableData')
  targetTableData = computed(() => {
    const tableDataTrue = newValue || []
    let result = []
    if (tableDataTrue) {
      result = tableDataTrue.map(item => ({
        ...item,
        email: item.mailContactEmailList.filter(_ => _.isDefault === 1)[0]?.email,
        popoverVisible: false,
      }))
    }
    return result
  })
  console.log(targetTableData.value, 'targetTableDataddddd')
}, {deep: true})
//--------


const ruleForm = computed(() => store.state.customer.ruleForm);
const form = computed(() => store.state.customer.form);
const selections = computed(() => store.state.cusList.tableData);
const selectTarget = computed(() => store.state.customer.selectTarget);


// 计算手机号
const defaultPhoneNumber = computed({
  get() {
    const defaultPhone = form.value.phones.find(item => item.isDefault);
    return defaultPhone ? defaultPhone.phoneNumber : form.value.phones.length >= 1 ? form.value.phones[0].phoneNumber : [];
  },
  set(value) {
    const defaultPhone = form.value.phones.find(item => item.isDefault);
    if (defaultPhone) {
      defaultPhone.phoneNumber = value;
    }
  }
});
// 计算邮箱
const defaultEmail = computed({
  get() {
    const defaultEmailCurrent = form.value.emails.find(item => item.isDefault);
    return defaultEmailCurrent ? defaultEmailCurrent.email : form.value.emails.length >= 1 ? form.value.emails[0].email : [];
  },
  set(value) {
    const defaultEmailCurrent = form.value.emails.find(item => item.isDefault);
    if (defaultEmailCurrent) {
      defaultEmailCurrent.email = value;
    }
  }
});

const isSelected = computed(() => store.state.customer.isSelected)
const customerCodeEmpty = (rule, value, callback) => {
  if (value === "" && !isSelected.value) {
    callback(new Error("客户编号不能为空"));
  } else {
    // 选中了
    if (customerCodeReg.test(ruleForm.value.customerCode)) {
      callback()
    } else {
      callback(new Error("客户编号长度不能超过30个字符"));
    }
  }
}


const ruleFormRef = ref(null);
const rules = reactive({
  customerCode: [{
    validator: customerCodeEmpty,
    trigger: ["change", "blur"],
  }],
  name: [{required: true, message: "联系人姓名不能为空", trigger: ["change", "blur"]}],
  email: [
    {
      pattern: emailReg,
      message: '您输入的邮箱不符合规定',
      trigger: ["change", "blur"],
    }
  ],
  zoneAddress:
      [{required: true, message: "区域不能为空", trigger: ["change", "blur"]}],
  customerName:
      [{required: true, message: "客户名称不能为空", trigger: ["change", "blur"]}],
});


const disabledDate = (time) => {
  return time.getTime() > Date.now();
};

// 选择
const selectChange = (val) => {
  let target = selections.value.filter((item) => item.id === val)[0];
  store.commit("customer/changeSelect", target.customerType);
};

let otherDesCompanyVisible = ref(false);
const otherDesSalVisible = ref(false);
const showTextarea = () => {
  // 获取客户信息
  otherDesCompanyVisible.value = true;
};

const saveOtherDesCompany = () => {
  otherDesCompanyVisible.value = false;
};

let sendMoneytoYe = null;
try {
  const moneySendGrandson = inject('moneySendGrandson');
  if (typeof moneySendGrandson === 'function') {
    sendMoneytoYe = function () {
      moneySendGrandson(3000);
    };
  } else {
    throw new Error('moneySendGrandson is not a function');
  }
} catch (e) {
}


// 表单更换结构
// const enterToSpace = computed(() => {
//   return JSON.parse(JSON.stringify(ruleForm.value.marketingRemark).replace(/^\\n$/g, '&nbsp;'))
// })
const saveDataIsLoading = computed(() => store.state.customer.saveDataIsLoading)
// 提交用户信息
const saveData = throttle(async () => {
          return ruleFormRef.value.validate(async (valid) => {
            if (valid) {
              console.log("校验成功");
              otherDesSalVisible.value = false;
              console.log('ruleForm', ruleForm.value, tableData.value)
              console.log(isSaveCommit.value, 'isSaveCommit.value')
              if (isSaveCommit.value) {
                await store.dispatch(
                    "customer/saveActivity"
                );
                // 是否关闭 对话框
                // 数据清空
                ruleFormRef.value.resetFields()
                store.commit('customer/setRuleForm', {
                  zoneCode: "",
                  customerCode: "",
                  customerCodeDefault: "请输入",
                  zoneAddress: "",
                  customerName: "",
                  setUpTime: null,
                  economicNature: "",
                  companyWebsite: "",
                  companyAddress: "",
                  phoneWayList: [],
                  emailWayList: [],
                  companyFax: "",
                  companyRemark: "",
                  customerLevel: "",
                  customerType: "",
                  customerTypeId: "",
                  product: "",
                  marketingRemark: " ",
                  createBy: "",
                  branchAdmin: "",
                  teamId: "",
                  mangerVOList: [{managerName: ''}]
                })
                await store.commit('customer/setForm', {
                  phones: [{phoneNumber: "", isDefault: true}],
                  emails: [{email: "", isDefault: true}],
                })
                tableData.splice(0, tableData.length);
                await store.commit('customer/changeSelect', '')
                try {
                  sendMoneytoYe()
                } catch (e) {
                  console.log(e)
                }
                try {
                  _scrollTo("created_customer")
                } catch (e) {
                }
              } else {
                const customerType = computed(() => store.state.customer.selectTarget);
                console.log("未保存", customerType.value);
                try {
                  await store.dispatch("customer/UpdateActivity", {customerType: customerType.value, isAlert: true});
                  await store.dispatch("customer/getQueryById", {customerId: localStorage.getItem('customerId')});
                } catch (e) {
                  console.log(e)
                }
              }
              // 重制选择
              store.commit("customer/setIsSelected", false)
            } else {
              console.log("校验失败");
              await nextTick(() => {
                // 获取错误节点
                let isError = ruleFormRef.value.$el.getElementsByClassName('is-error')
                isError[0].scrollIntoView({
                  // 滚动到指定节点
                  // 值有start,center,end，nearest，当前显示在视图区域中间
                  block: 'center',
                  // 值有auto、instant,smooth，缓动动画（当前是慢速的）
                  behavior: 'smooth',
                })
              })
              return false;
            }
          });
        },
        1000
    )
;
defineExpose({saveData, ruleFormRef})
// 返回
const reBack = () => {
  // localStorage.setItem('activeMenu', '/email/customer/list')
  router.push({
    path: `/email/customer/list`,
  });
};

function _scrollTo(id) {
  var _id = document.getElementById(id);
  console.log("id", _id)
  document.querySelector('.view').scrollTo({
    top: _id.offsetTop,
    behavior: 'smooth'
  });
}


const isSaveCommit = ref(true);
onMounted(async () => {
  // 保留用户表单信息
  // const result = computed(() => {
  //   const tableDataTrue = JSON.parse(localStorage.getItem("tableData")) || []
  //   let result = []
  //   if (tableDataTrue) {
  //     result = tableDataTrue.map(item => ({
  //       ...item,
  //       email: item.mailContactEmailList.filter(_ => _.isDefault === 1)[0]?.email,
  //       popoverVisible: false,
  //     }))
  //   }
  //   return result
  // })
  // Object.assign(tableData, result.value)
  // console.log(result.value, tableData,
  //     'targetTableData'
  // )
  await store.dispatch("cusList/getMailCustomerLevel");
  await store.commit('customer/setCustomerLevels', selections.value)
  const res = router.currentRoute.value.name;
  console.log(res, '@@')
  if (res === "Create" || res === "Inbox" || res === "HasSend" || res === 'DetailEmail' || res === 'Success') {
    isSaveCommit.value = true;
  } else {
    isSaveCommit.value = false;
  }
});

const changeBirthday = (val) => {
  console.log(val)
}
</script>

<style lang="less" scoped>
@import "../../../views/Customer/less/detail.less";

.inner_outbox {
  display: flex;
  justify-content: flex-start;
  height: 86px !important;
}

.inner_outbox {
  .el-form-item:last-child {
    height: max-content !important;
  }
}


:deep(.el-date-editor.el-input) {
  width: 100% !important;
}

:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  justify-content: center !important;
}

#submits_footer {
  height: max-content !important;
  padding: 0px !important;
  margin: 0px !important;
}


h2 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  font-size: 21px;
}

.pages {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.top_titles {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.small_box {
  width: max-content;
  margin-top: 20px;
}

:deep(.el-drawer) {
  width: 530px;
}

.box_content {
  width: 100%;

  & > div {
    background: #f5f5f5;

    .inners {
      padding: 20px;
      width: 100%;
      background: white;
    }
  }
}

h3 {
  font-size: 16px;
  font-weight: 500;
}

:deep(.el-tabs__active-bar) {
  height: 3px !important;
}

.titles {
  padding: 20px 0px;
  border-bottom: 1px solid #ebebeb;
}

:deep(.el-form-item__label) {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-form-item:first-child) {
  padding: 0px 24px 12px 0px;
}

.views {
  flex-grow: 1;
  height: calc(100vh - 167px);
  overflow: hidden;
  background-color: rgb(245, 245, 245);
}

.linkUser {
  display: flex;
  overflow: hidden;
  height: calc(100vh - 182px);
}

:deep(.el-empty__description) {
  margin-top: -20px;
}

#spButton {
  margin-left: -40px !important;
}

.tops {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 20px;

  .span_block {
    width: 4px;
    height: 16px;
    padding-left: 5px;
    border-left: 4px solid #1890ff;
  }

  .inner_text {
    font-weight: 700;
    color: #000;
  }
}

</style>
